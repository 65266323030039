import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        pengaduan: {
            pengaduan_id: '',
            pengaduan_no_tiket: '',
            pengaduan_slug: '',
            pengaduan_judul: '',
            pengaduan_isi: '',
            pengaduan_pengadu: '',
            pengaduan_created_at: '',
            jp_id: '',
            jp_slug: '',
            jp_nama: '',
            jp_ket: '',
            jp_created_at: '',
            dp_id: '',
            dp_posisi: '',
            dp_ket: '',
            dp_status: '',
            dp_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        viewModal(state, value) {
            state.pengaduan = value
        }
    }
})
