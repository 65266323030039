<template>
  <v-dialog
    v-model="modalView"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Pengaduan</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <!-- Nomor Tiket Pengaduan -->
                <v-col cols="12" md="6" class="mb-n8">
                  <span class="subtitle-2">Nomor Tiket Pengaduan</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="viewItem.pengaduan_no_tiket"
                    autocomplete="off"
                    filled
                    readonly
                  ></v-text-field>
                </v-col>

                <!-- Nama Pengadu -->
                <v-col cols="12" md="6" class="mb-n8">
                  <span class="subtitle-2">Nama Pengadu</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="viewItem.pengaduan_pengadu"
                    autocomplete="off"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Judul Pengaduan -->
                <v-col cols="12" md="12" class="mb-n8">
                  <span class="subtitle-2">Judul Pengaduan</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="viewItem.pengaduan_judul"
                    autocomplete="off"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Jenis Pengaduan -->
                <v-col cols="12" md="12" class="mb-n8">
                  <span class="subtitle-2">Jenis Pengaduan</span>
                  <v-text-field
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="viewItem.jp_nama"
                    autocomplete="off"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <!-- Isi Pengaduan -->
                <v-col cols="12" md="12">
                  <span class="subtitle-2">Isi Pengaduan</span>
                  <v-textarea
                    dense
                    flat
                    outlined
                    class="mt-2"
                    v-model="viewItem.pengaduan_isi"
                    autocomplete="off"
                    readonly
                  >
                  </v-textarea>
                </v-col>
              </v-row>

              <hr />
              <div
                class="text-right mr-5 mt-5 pb-5"
                v-if="selesai != 'PengaduanSelesaiAdmin'"
              >
                <v-btn
                  v-if="btnLoading"
                  color="success"
                  depressed
                  @click="tindaklanjut()"
                  >Tindak Lanjuti</v-btn
                >
                <v-btn v-else color="success" depressed loading
                  >Tindak Lanjuti</v-btn
                >
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <v-card
                outlined
                style="max-height: 535px"
                class="overflow-y-auto"
              >
                <v-card-title>
                  <v-icon left>mdi-history</v-icon>
                  Riwayat Tindak Lanjut
                </v-card-title>

                <div class="px-4" v-if="alertGagal">
                  <v-alert type="error" dense>
                    <span>Nomor Tiket <strong>Tidak</strong> Ditemukan!</span>
                  </v-alert>
                </div>

                <div class="px-4">
                  <v-timeline dense>
                    <div v-for="(item, i) in trackingData" :key="i">
                      <v-timeline-item
                        :color="
                          item.dp_status === 'selesai' ? 'success' : 'grey'
                        "
                        small
                        fill-dot
                      >
                        <v-alert
                          :color="
                            item.dp_status === 'selesai' ? 'success' : 'grey'
                          "
                          class="white--text"
                        >
                          <div>
                            <v-icon color="white" small class="mr-2"
                              >mdi-clock-time-nine-outline</v-icon
                            >
                            <span class="caption">
                              {{ tglFormat(item.dp_created_at) }}
                            </span>
                          </div>
                          <div class="mt-2">
                            {{ item.dp_ket }}
                          </div>
                        </v-alert>
                      </v-timeline-item>
                    </div>
                  </v-timeline>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <CModalViewTL v-if="selesai != 'PengaduanSelesaiAdmin'" />
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import CModalViewTL from "@/components/Admin/pengaduan/pengaduan/modalTL";

import modalView from "@/store/pengaduan/modalView";
import modalTL from "@/store/tindaklanjut/modalView";

export default {
  components: {
    CModalViewTL,
  },

  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.pengaduan;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalView() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.noTicket = await this.viewItem.pengaduan_no_tiket;
      this.tracking();
      this.selesai = this.$route.name;
    },
  },

  data: () => ({
    session: "",
    btnLoading: true,
    alertGagal: false,

    trackingData: [],
    noTicket: "",

    selesai: "",
  }),

  methods: {
    tracking() {
      this.http
        .get(
          process.env.VUE_APP_API_BASE + "tindaklanjut?noTiket=" + this.noTicket
        )
        .then((res) => {
          this.trackingData = res.data.data;
          this.alertGagal = false;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertGagal = true;
        });
    },

    closeModal() {
      this.modalView = false;
    },

    tindaklanjut() {
      modalTL.commit("toggleModal", true);
      modalTL.commit("pengaduanID", this.viewItem.pengaduan_id);
      modalTL.commit("dpID", this.viewItem.dp_id);
    },

    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },
  },
};
</script>
