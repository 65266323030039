<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Tindak Lanjut</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModalTL()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="mx-7 mt-5">
          <v-row>
            <!-- Isi Pengaduan -->
            <v-col cols="12" md="12">
              <span class="subtitle-2">Keterangan</span>
              <v-textarea
                dense
                flat
                outlined
                class="mt-2"
                v-model="dp_ket"
                autocomplete="off"
              >
              </v-textarea>
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mt-5 pb-5">
            <v-btn v-if="btnLoadingS" class="mr-2" color="success" depressed @click="selesai()"
              >Selesai</v-btn
            >
            <v-btn v-else color="success" class="mr-2" depressed loading>Selesai</v-btn>

            <v-btn v-if="btnLoading" color="primary" depressed @click="update()"
              >Simpan</v-btn
            >
            <v-btn v-else color="primary" depressed loading>Simpan</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import refreshView from "@/store/pengaduan/viewPengaduan";
import modalView from "@/store/tindaklanjut/modalView";
import modalViewPengaduan from "@/store/pengaduan/modalView";

export default {
  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    pengaduan_id: {
      get() {
        return modalView.state.pengaduan_id;
      },
      set(value) {
        console.log(value);
      },
    },

    dp_id: {
      get() {
        return modalView.state.dp_id;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  created() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  watch: {
    async modalView() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    },
  },

  data: () => ({
    session: "",
    CWidth: "60%",
    btnLoading: true,
    btnLoadingS: true,

    dp_ket: "",
  }),

  methods: {
    async update() {
      this.btnLoading = false;

      const data = {
        dp_id: this.dp_id,
        pengaduan_id: this.pengaduan_id,
        dp_ket: this.dp_ket,
      };

      const url = process.env.VUE_APP_API_BASE + "tindaklanjut";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    async selesai() {
      this.btnLoading = false;

      const data = {
        dp_id: this.dp_id,
        pengaduan_id: this.pengaduan_id,
        dp_ket: this.dp_ket,
      };

      const url = process.env.VUE_APP_API_BASE + "tindaklanjut/selesai";
      this.http
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },

    closeModal() {
      this.modalView = false;
      modalViewPengaduan.commit("toggleModal", false);
    },

    closeModalTL() {
      this.modalView = false;
    },
  },
};
</script>
