import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalView: false,
        pengaduan_id: '',
        dp_id: ''
    },

    mutations: {
        toggleModal(state, value) {
            state.modalView = value
        },
        pengaduanID(state, value) {
            state.pengaduan_id = value
        },
        dpID(state, value) {
            state.dp_id = value
        }
    }
})
